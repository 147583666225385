import React from 'react';
import PropTypes from 'prop-types';

const DocumentsContext = React.createContext();

export const DocumentsProvider = ({ children, value }) => {
  const [documents, setDocuments] = React.useState([]);

  return (
    <DocumentsContext.Provider
      value={
        value || {
          documents,
          setDocuments,
        }
      }
    >
      {children}
    </DocumentsContext.Provider>
  );
};

export const useDocuments = () => {
  const context = React.useContext(DocumentsContext);
  if (context === undefined) {
    throw new Error('useDocuments must be used within a DocumentsProvider');
  }

  return context;
};

DocumentsProvider.defaultProps = {
  value: undefined,
};

DocumentsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.shape({
    documents: PropTypes.arrayOf(PropTypes.shape({
      companyId: PropTypes.string,
      createdDate: PropTypes.string,
      docLink: PropTypes.string,
      docName: PropTypes.string,
      docType: PropTypes.string,
      id: PropTypes.string,
      paymentInstructionId: PropTypes.string,
      relatedCompanyName: PropTypes.string,
      reviewedBy: PropTypes.string,
      reviewedDate: PropTypes.string,
      status: PropTypes.string,
    })),
    setDocuments: PropTypes.func,
  }),
};
